<template>
  <footer>
    <p>&copy; 2024 必显科技</p>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style scoped>
footer {
  text-align: center;
  padding: 1rem;
  bottom: 0;
  width: 100%;
}
</style>
