<template>
  <div>
    <h1>Welcome to the Product Showcase</h1>
  </div>
</template>

<script>

export default {
  name: 'AboutPage',
  components: {
  },
}
</script>
