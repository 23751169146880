<template>
  <div>
    <h1>Product Detail {{ $route.params.id }}</h1>
  </div>
</template>

<script>
export default {
  name: 'ProductDetail',
  components: {
  },
}
</script>
